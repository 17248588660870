import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Error404 } from "..";
import Content from "./Content";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Lerom() {
  const navigate = useNavigate();
  let query = useQuery();

  useEffect(() => {
    fetch("https://pixel.dvinahome.ru/api/v1/feedback/ok", {
      method: "POST",
      body: JSON.stringify({ id: query.get("id"), type: query.get("type") }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((r) => {
        if (r.code !== 200) {
          return navigate("/error?code=401");
        }
      });
  });
  if (query.get("type")) {
    return (
      <Content from={query.get("from")} type={query.get("type")} client={query.get("client")} id={query.get("id")} />
    );
  } else {
    return <Error404 />;
  }
}
