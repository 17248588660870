import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { Rate, Input, Descriptions, Button, Typography, Form, Spin } from "antd";
import { Widget } from "@uploadcare/react-widget";
import "../css/App.css";

const { TextArea } = Input;
const { Title, Text } = Typography;

function Spinner({ show = false }) {
  return (
    <>
      <div
        hidden={!show}
        style={{
          position: "absolute",
          top: 0,
          width: "120vw",
          height: "120vh",
          zIndex: 999,
          backgroundColor: "rgb(0,0,0,0.2)",
        }}>
        <Spin
          style={{
            display: "block",
            position: "fixed",
            top: "calc(50% - (58px / 2))",
            right: " calc(50% - (58px / 2))",
          }}
        />
      </div>
    </>
  );
}

function Content({ type, client, id, from }) {
  const [form] = Form.useForm();
  const [spiner, setSpiner] = useState(false);
  const [files, setFiles] = useState({});
  const navigate = useNavigate();

  function fetchWithTimeout(url, options, timeout = 10000) {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) => setTimeout(() => reject(new Error("timeout")), timeout)),
    ]);
  }

  const sendRequest = async (data) => {
    setSpiner(true);
    await fetchWithTimeout(
      "https://pixel.dvinahome.ru/api/v1/feedback",
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      15000
    )
      .then((res) => {
        if (res.ok) {
          setSpiner(false);
          return res.json();
        }
      })
      .then((r) => {
        if (r.code === 200) return window.location.replace(`/success`);
        else if (r.code === 400) {
          throw new Error(r.text);
        }
      })
      .catch((err) => {
        return navigate(`/error#${err.message}`);
      });
  };

  const rateToText = (value, name, nameParent) => {
    switch (value) {
      case 0:
        form.setFieldValue(nameParent, undefined);
        form.setFieldValue(name, "");
        break;
      case 1:
        form.setFieldValue(name, "плохо");
        break;
      case 2:
        form.setFieldValue(name, "удовлетворительно");
        break;
      case 3:
        form.setFieldValue(name, "нормально");
        break;
      case 4:
        form.setFieldValue(name, "хорошо");
        break;
      case 5:
        form.setFieldValue(name, "отлично");
        break;
      default:
    }
  };

  switch (type) {
    case "delivery":
      return (
        <>
          <Spinner show={spiner} />
          <Container style={{ width: "90%", height: "90%" }}>
            <Row style={{ paddingTop: "2em" }}>
              <Col sm="auto" style={{ paddingBottom: "10px" }}>
                <img
                  alt="logo"
                  src="https://ucarecdn.com/7f34c711-ef20-4618-9514-b67ff685558c/Logo_MOON_RGB_PNG2.png"
                  width={200}
                />
              </Col>
              <Col sm={12}>
                <Text
                  style={{
                    fontSize: "0.7em",
                    color: "#00000073",
                    backgroundColor: "#F8F8F8",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  оставьте отзыв о качестве работы нашей компании
                </Text>
              </Col>
            </Row>
            <Row style={{ paddingTop: "5%", textAlign: "justify" }}>
              <Col>
                <Title style={{ fontWeight: 500 }} level={5}>
                  Уважаемый(ая), {client}, Вам доставен товар мебельной фабрики Moon!
                </Title>
                <Text>
                  Мы рады любым отзывам: <br />
                  <ul>
                    <li>
                      <span style={{ color: "green" }}>позитивным</span> — над вашим заказом работало много людей, им
                      важно услышать «спасибо» в свой адрес
                    </li>
                    <li>
                      <span style={{ color: "red" }}>негативным</span> — они помогут узнать нам о проблеме и решить её
                    </li>
                  </ul>
                </Text>
              </Col>
            </Row>
            <Form form={form} layout="horizontal">
              <Descriptions
                size="small"
                column={{ xs: 1, sm: 2, md: 2 }}
                layout="vertical"
                title={
                  <Text style={{ fontWeight: 400 }} level={5}>
                    Оцените, пожалуйста, качество:
                  </Text>
                }>
                <Descriptions.Item label="Доставки товара">
                  <Form.Item name={"deliveryRateValue"} rules={[{ required: true, message: "укажите оценку" }]}>
                    <Rate onChange={(value) => rateToText(value, "deliveryRate", "deliveryRateValue")} />
                  </Form.Item>
                  <Form.Item name={"deliveryRate"}>
                    <Input readOnly bordered={false} className="rate-text" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Вежливость сотрудника(ов) сервиса">
                  <Form.Item name={"servicemanRateValue"} rules={[{ required: true, message: "укажите оценку" }]}>
                    <Rate onChange={(value) => rateToText(value, "servicemanRate", "servicemanRateValue")} />
                  </Form.Item>
                  <Form.Item name={"servicemanRate"}>
                    <Input bordered={false} readOnly className="rate-text" />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={{ xs: 1, sm: 2, md: 2 }} layout="vertical" size="small">
                <Descriptions.Item label="Общая оценка">
                  <Form.Item name={"sumRateValue"} rules={[{ required: true, message: "укажите оценку" }]}>
                    <Rate onChange={(value) => rateToText(value, "sumRate", "sumRateValue")} />
                  </Form.Item>
                  <Form.Item name={"sumRate"}>
                    <Input bordered={false} readOnly className="rate-text" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Ваш комментарий по заказу:">
                  <Form.Item name={"textValue"}>
                    <TextArea
                      style={{ width: 320 }}
                      showCount
                      rows={4}
                      placeholder="напишите ваши впечатления от покупки"
                      maxLength={500}
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item>
                  <label style={{ paddingRight: 5 }} htmlFor="file">
                    Загрузите фото товаров:
                  </label>
                  <Widget
                    locale="ru"
                    multiple
                    publicKey="4bc64d30a9c9fbca09d2"
                    id="file"
                    onChange={(value) => setFiles(value)}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Row style={{ paddingBottom: "2em", paddingTop: "2em" }}>
                <Col sm={12}>
                  <Button
                    style={{ backgroundColor: "#930345" }}
                    color="#"
                    size="large"
                    type="primary"
                    onClick={() => {
                      form
                        .validateFields(["deliveryRateValue", "servicemanRateValue", "sumRateValue"])
                        .then((values) => {
                          sendRequest({
                            id: id,
                            deliveryRateValue: form.getFieldValue("deliveryRateValue") || 0,
                            servicemanRateValue: form.getFieldValue("servicemanRateValue") || 0,
                            sumRateValue: form.getFieldValue("sumRateValue") || 0,
                            textValue: form.getFieldValue("textValue") || "",
                            type,
                            files: files,
                            from: from || "",
                          });
                        })
                        .catch((err) => {});
                    }}>
                    отправить отзыв
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      );
    case "assembly":
      return (
        <>
          <Spinner show={spiner} />
          <Container style={{ width: "90%", height: "90%" }}>
            <Row style={{ paddingTop: "2em" }}>
              <Col sm="auto" style={{ paddingBottom: "10px" }}>
                <img
                  alt="logo"
                  src="https://ucarecdn.com/7f34c711-ef20-4618-9514-b67ff685558c/Logo_MOON_RGB_PNG2.png"
                  width={200}
                />
              </Col>
              <Col sm={12}>
                <Text
                  style={{
                    fontSize: "0.7em",
                    color: "#00000073",
                    backgroundColor: "#F8F8F8",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  оставьте отзыв о качестве работы нашей компании
                </Text>
              </Col>
            </Row>
            <Row style={{ paddingTop: "5%", textAlign: "justify" }}>
              <Col>
                <Title style={{ fontWeight: 500 }} level={5}>
                  Уважаемый(ая), {client}, Вам была оказана услуга сборки товара мебельной фабрики Moon!
                </Title>
                <Text>
                  Мы рады любым отзывам: <br />
                  <ul>
                    <li>
                      <span style={{ color: "green" }}>позитивным</span> — над вашим заказом работало много людей, им
                      важно услышать «спасибо» в свой адрес
                    </li>
                    <li>
                      <span style={{ color: "red" }}>негативным</span> — они помогут узнать нам о проблеме и решить её
                    </li>
                  </ul>
                </Text>
              </Col>
            </Row>
            <Form form={form} layout="horizontal">
              <Descriptions
                size="small"
                column={{ xs: 1, sm: 2, md: 2 }}
                layout="vertical"
                title={
                  <Text style={{ fontWeight: 400 }} level={5}>
                    Оцените, пожалуйста, качество:
                  </Text>
                }>
                <Descriptions.Item label="Сборки товара(ов)">
                  <Form.Item name={"assemblyRateValue"} rules={[{ required: true, message: "укажите оценку" }]}>
                    <Rate onChange={(value) => rateToText(value, "assemblyRate", "assemblyRateValue")} />
                  </Form.Item>
                  <Form.Item name={"assemblyRate"}>
                    <Input readOnly bordered={false} className="rate-text" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Вежливость сотрудника(ов) сервиса">
                  <Form.Item name={"servicemanRateValue"} rules={[{ required: true, message: "укажите оценку" }]}>
                    <Rate onChange={(value) => rateToText(value, "servicemanRate", "servicemanRateValue")} />
                  </Form.Item>
                  <Form.Item name={"servicemanRate"}>
                    <Input bordered={false} readOnly className="rate-text" />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={{ xs: 1, sm: 2, md: 2 }} layout="vertical" size="small">
                <Descriptions.Item label="Общая оценка">
                  <Form.Item name={"sumRateValue"} rules={[{ required: true, message: "укажите оценку" }]}>
                    <Rate onChange={(value) => rateToText(value, "sumRate", "sumRateValue")} />
                  </Form.Item>
                  <Form.Item name={"sumRate"}>
                    <Input bordered={false} readOnly className="rate-text" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Ваш комментарий по заказу:">
                  <Form.Item name={"textValue"}>
                    <TextArea
                      style={{ width: 320 }}
                      showCount
                      rows={4}
                      placeholder="напишите ваши впечатления от покупки"
                      maxLength={500}
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item>
                  <label style={{ paddingRight: 5 }} htmlFor="file">
                    Загрузите фото товаров:
                  </label>
                  <Widget
                    locale="ru"
                    multiple
                    publicKey="4bc64d30a9c9fbca09d2"
                    id="file"
                    onChange={(value) => setFiles(value)}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Row style={{ paddingBottom: "2em", paddingTop: "2em" }}>
                <Col sm={12}>
                  <Button
                    style={{ backgroundColor: "#930345" }}
                    color="#"
                    type="primary"
                    size="large"
                    onClick={() => {
                      form
                        .validateFields(["assemblyRateValue", "servicemanRateValue", "sumRateValue"])
                        .then((values) => {
                          sendRequest({
                            id: id,
                            assemblyRateValue: form.getFieldValue("assemblyRateValue") || 0,
                            servicemanRateValue: form.getFieldValue("servicemanRateValue") || 0,
                            sumRateValue: form.getFieldValue("sumRateValue") || 0,
                            textValue: form.getFieldValue("textValue") || "",
                            type,
                            files: files,
                            from: from || "",
                          });
                        })
                        .catch((err) => {});
                    }}>
                    отправить отзыв
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      );
    case "in_shop":
      return (
        <>
          <Spinner show={spiner} />
          <Container style={{ width: "90%", height: "90%" }}>
            <Row style={{ paddingTop: "2em" }}>
              <Col sm="auto" style={{ paddingBottom: "10px" }}>
                <img
                  alt="logo"
                  src="https://ucarecdn.com/7f34c711-ef20-4618-9514-b67ff685558c/Logo_MOON_RGB_PNG2.png"
                  width={200}
                />
              </Col>
              <Col sm={12}>
                <Text
                  style={{
                    fontSize: "0.7em",
                    color: "#00000073",
                    backgroundColor: "#F8F8F8",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  оставьте отзыв о качестве работы нашей компании
                </Text>
              </Col>
            </Row>
            <Row style={{ paddingTop: "5%", textAlign: "justify" }}>
              <Col>
                <Title style={{ fontWeight: 500 }} level={5}>
                  Уважаемый(ая), {client}, Вы приобрели товары мебельной фабрики Moon!
                </Title>
                <Text>
                  Мы рады любым отзывам: <br />
                  <ul>
                    <li>
                      <span style={{ color: "green" }}>позитивным</span> — над вашим заказом работало много людей, им
                      важно услышать «спасибо» в свой адрес
                    </li>
                    <li>
                      <span style={{ color: "red" }}>негативным</span> — они помогут узнать нам о проблеме и решить её
                    </li>
                  </ul>
                </Text>
              </Col>
            </Row>
            <Form form={form} layout="horizontal">
              <Descriptions
                size="small"
                column={{ xs: 1, sm: 2, md: 2 }}
                layout="vertical"
                title={
                  <Text style={{ fontWeight: 400 }} level={5}>
                    Оцените, пожалуйста, качество:
                  </Text>
                }>
                <Descriptions.Item label="Консультация менеджера">
                  <Form.Item name={"managerRateValue"} rules={[{ required: true, message: "укажите оценку" }]}>
                    <Rate onChange={(value) => rateToText(value, "managerRate", "managerRateValue")} />
                  </Form.Item>
                  <Form.Item name={"managerRate"}>
                    <Input readOnly bordered={false} className="rate-text" />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={{ xs: 1, sm: 2, md: 2 }} layout="vertical" size="small">
                <Descriptions.Item label="Общая оценка">
                  <Form.Item name={"sumRateValue"} rules={[{ required: true, message: "укажите оценку" }]}>
                    <Rate onChange={(value) => rateToText(value, "sumRate", "sumRateValue")} />
                  </Form.Item>
                  <Form.Item name={"sumRate"}>
                    <Input bordered={false} readOnly className="rate-text" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Ваш комментарий по заказу:">
                  <Form.Item name={"textValue"}>
                    <TextArea
                      style={{ width: 320 }}
                      showCount
                      rows={4}
                      placeholder="напишите ваши впечатления от покупки"
                      maxLength={500}
                    />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item>
                  <label style={{ paddingRight: 5 }} htmlFor="file">
                    Загрузите фото товаров:
                  </label>
                  <Widget
                    locale="ru"
                    multiple
                    publicKey="4bc64d30a9c9fbca09d2"
                    id="file"
                    onChange={(value) => setFiles(value)}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Row style={{ paddingBottom: "2em", paddingTop: "2em" }}>
                <Col sm={12}>
                  <Button
                    style={{ backgroundColor: "#930345" }}
                    color="#"
                    size="large"
                    type="primary"
                    onClick={() => {
                      form
                        .validateFields(["managerRateValue", "sumRateValue"])
                        .then((values) => {
                          sendRequest({
                            id: id,
                            managerRateValue: form.getFieldValue("managerRateValue") || 0,
                            sumRateValue: form.getFieldValue("sumRateValue") || 0,
                            textValue: form.getFieldValue("textValue") || "",
                            type,
                            files: files,
                            from: from || "",
                          });
                        })
                        .catch((err) => {});
                    }}>
                    отправить отзыв
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </>
      );
    default:
  }
}

export default Content;
