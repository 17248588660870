import React from "react";
import { Result } from "antd";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Error404() {
  let query = useQuery();
  switch (query.get("code") || "") {
    case "401":
      return <Result title="Спасибо! Вы уже оставили отзыв" />;
      break;
    default:
      return (
        <Result status="404" title="404" subTitle="Простите, страница на которую вы перешли закрыта для просмотра" />
      );
  }
}
