import React from "react";
import { Result } from "antd";

function Success() {
  return (
    <Result
      status="success"
      title={
        <>
          Спасибо за отзыв! <br /> Нам важно Ваше мнение. Отзывы помогают нам становиться лучше!
        </>
      }
      subTitle=""
    />
  );
}

export { Success };
