import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Askona, Lerom, Moon, Error404 } from "./components";
import "bootstrap/dist/css/bootstrap.min.css";
import { Success } from "./components/Success/Success";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route path="/askona" element={<Askona />} />
        <Route path="/lerom" element={<Lerom />} />
        <Route path="/moon" element={<Moon />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </Router>
  );
}

export default App;
